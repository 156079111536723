.App-Loading-Bar {
    position: relative;
    margin-top: 50%;
    background-color: navy;
    transition: .20s all ease-in
  }
  
  .App-Loading-Bar-Init {
    height: 5px;
    width: 0;
  }
  
  .App-Loading-Bar-Final {
    height: 5px;
    width: 100%;
  }
  
  .App-Loading-Bar-Post {
    margin-top: 0;
    width: 100%;
    height: 100vh;
  }

  .App-Faded {
    opacity: 0;
  }

  .App-Showing {
    opacity: 1;
  }

  .Nav-Brand {
    font-size: 18pt;
  }

