.Devices {
    width: 100%;
    padding: 15px;
}

.Device-Sections-Container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    height: 100%;
}

.Devices-Header {
    width: 100%;
    text-align: center;
    font-size: 24pt;
    color: #fff;
    text-shadow: 4px 4px 8px #000;
    font-weight: bold;
}

.Devices-List {
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.Devices-List-Header {
    width: 100%;
    min-width: 320px;
    height: 39px;
    padding: 5px;
    box-shadow: 0 3px 6px #455A64;
    display: inline-block;
    background-color: navy;
    border-radius: 5px;
}

.Device-List-Header-Label {
    color: #fff;
    opacity: 9;
    font-weight: bold;
    text-align: center;
    float: left;
    font-size: 13px;
    margin-top: 5px;
    width: 31%; 
}

.Device-List-Row {
    margin-top: 5px;
    width: 100%; 
    display: inline-block;
    cursor: pointer;
}

.Device-List-Item {
    color: #fff;
    font-weight: bold;
    text-align: center;
    float: left;
    font-size: 11px;
    margin-top: 5px;
    width: 31%; 
}

.Device-Img {
    margin-top: 20px;
    width: 400px;
}

.Device-Button {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px;
    text-align: center;
    font-weight: bold;
    width: 80px;
    transition: .3s all ease;
    cursor: pointer;
    margin: 0 auto;
}

.Device-Button:hover {
    box-shadow: 0 0 8px #000;
}

.Device-Add-Container {
    width: 50%;
    min-width: 300px;
    margin: 0 auto;
    border: 1px solid #000;
    padding: 5px;
    display: flex;
}

.Device-Container {
    width: 100%;
    margin: 0 auto;
    margin-left: -25px;
}

.Config-Container {
    width: 100%;
    margin: 10px auto;
    margin-left: -15px;
}

.Config-Title {
    width: 100%;
    height: 49px;
    padding: 5px;
    box-shadow: 0 3px 6px #455A64;
    display: inline-block;
    background-color: #fff;
    border-radius: 5px;
    color: navy;
}

.Config-Title-Label {
    font-weight: bold;
    text-align: center;
    float: left;
    font-size: 12px;
    margin-top: 5px;
}

.Config-Values-Row {
    margin-top: 15px;
    width: 100%; 
    display: inline-block;
}

.Config-Value-Text {
    float: left;
    width: 45%;
}

.Config-Value-Color {
    float: left;
    width: 25%;
}

.Config-Value-Move {
    float: left;
    width: 10%;
    font-size: 18pt;
    cursor: pointer;
    margin-top: -10px;
}

.Config-Value-Move:hover {
    color: #fff;
}

.Config-Value-Delete {
    float: left;
    width: 10%;
    text-align: center;
    margin-top: -10px;
}


.Config-Value-Delete:hover {
    color: #fff;
}

.Config-Save {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 5%;
    cursor: pointer;
    transition: .3s all ease;
    text-align: center;
}

.Config-Save:hover {
    box-shadow: 0 0 8px #000;
}

.Confirm-Container {
    position: absolute;
    top: 20%;
    width: 400px;
    left: calc(50% - 100px);
    border: 2px sold navy;
    border-radius: 5px;
    box-shadow: 5px 5px 8px #000;
    z-index: 2;
    background-color: #fff;
    padding: 5px;
}

.Confirm-Title {
    width: 100%;
    font-size: 24pt;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}

.Confirm-Button-Row {
    width: 100%;
    display: inline-block;  
    margin-top: 15px;  
}

.Confirm-Button {
    width: 75px;
    text-align: center;
    border-radius: 5px;
    font-size: 11pt;
    font-weight: bold;
    transition: .3s all ease;
    padding: 5px;
    cursor: pointer;
}

.Confirm-Button:hover {
    box-shadow: 0 0 8px #000;
}