.carousel-inner > .carousel-item {
    height: 80vh;
 }

 .carousel-item > img {
     margin-left: 13%;
 }

 .carousel-indicators {
     margin-left: 20%;
 }